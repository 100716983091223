import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axiosInstance from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import Pagination from "../../Pagination";

const TableKartuSoal = (props) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const inputEl = useRef(null);

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    // setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  // activePag.current === 0 && chageData(0, sort);
  // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    // activePag.current = i;
    // chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  const [selectedSoal, setSelectedSoal] = useState(null);

  const [price, setPrice] = useState(0);

  const handleSubmitBursa = async () => {
    if (!price) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Harga harus di isi'
      })
      return
    }
    console.log("price:", price);
    console.log("selectedSoal:", selectedSoal);
    var params = {
      price: price
    }
    var resPrice = await axiosInstance.put(`soal/price/${selectedSoal.id}`, params);
    var resBursa = await axiosInstance.put(`soal/activate-bursa/${selectedSoal.id}`, {});
    if (resPrice.data && resBursa.data) {
      props.onBursa(true)
      handleClose();
    }
  };

  const goTo = (action, contact) => {
    console.log("goTo", contact);
    navigate(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`);
  }

  const onPaging = (page) => {
    props.onPaging(page);
  }
  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    <th className="sorting">ID Kartu Soal</th>
                    <th className="sorting">Judul</th>
                    <th className="sorting">Jenis</th>
                    <th className="sorting">Status</th>
                    <th className="sorting">Pembuat</th>
                    <th className="sorting">Action</th>
                    <th className="sorting">Statistik</th>
                  </tr>
                </thead>

                <tbody>
                  {props.contacts.map((v, i) => (
                    <tr className={(i + 1) % 2 == 1 ? "odd" : "even"} key={i}>
                      <td>{i + 1}</td>
                      <td>{v.kode_paket}</td>
                      <td>
                        {v.paket_soal_name} {i + 1}
                      </td>
                      <td>
                        {v.jenis_soal.map((value, key) => (
                          <span className={`badge m-1 badge-${value.color}`}>
                            {value.name}
                          </span>
                        ))}
                      </td>
                      <td>
                        <span
                          className={`badge bg-${
                            v.is_draft ? "secondary" : "success"
                          }`}
                        >
                          {v.is_draft ? "Draft" : "Done"}
                        </span>

                        {v.is_bursa ? (
                          <div className="badge badge-dark ml-1">Bursa</div>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{v.author}</td>
                      <td>
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary text-danger"
                            onClick={() => props.onDelete(v.id)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary text-warning"
                            onClick={() => goTo('update', v)}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => goTo('detail', v)}
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                          {!v.is_draft && !v.is_bursa ? (
                            <button
                              type="button"
                              onClick={() => handleShow(v)}
                              className="btn btn-outline-primary"
                            >
                              <i className="fas fa-plus"></i> Bursa
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`/kartu-soal/statistik/${v.kode_paket}`}
                          className="btn btn-sm btn-outline-primary"
                        >
                          <i className="fas fa-chart-bar"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination totalData={props.total} dataPerPage={props.perPage} onChange={onPaging} />

              {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/kartu-soal"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.curreU - 1)
                    }
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/kartu-soal"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/kartu-soal"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Modal dialogClassName="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bursa Kartu Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group">
            <b className="mb-3">Sampul</b>
            <Dropzone
              inputContent="Drop files here to upload"
              className="mt-3"
              accept="image/*,audio/*,video/*"
              styles={{
                dropzone: {
                  minHeight: 200,
                  maxHeight: 250,
                  width: "100%",
                  backgroundColor: "#f2f4fa",
                  border: "1px dashed #DDDFE1",
                  overflow: "hidden",
                },
                inputLabel: {
                  color: "#7e7e7e",
                  fontSize: "16px",
                  fontWeight: "normal",
                  backgroundColor: "#f2f4fa",
                },
              }}
            />
          </div> */}
          <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input type="number" className="form-control mt-2" placeholder="Masukkan Harga Scoin" value={price} onChange={(e) => setPrice(e.target.value)}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSubmitBursa}>
            Masukkan Bursa
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TableKartuSoal;
