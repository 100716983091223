import React, { useEffect, useImperativeHandle, useState } from "react";
import Select from "react-select";
import axiosInstance from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";

const PilihPesertaUjian = React.forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    getData() {
      return getFormData()
    }
  }))

  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [jenjangOpt, setJenjangOpt] = useState([
    // { value: "PAUD", label: "PAUD" },
    // { value: "SD/MI", label: "SD/MI" },
    // { value: "SMP/MTs", label: "SMP/MTs" },
    // { value: "SMA/MA", label: "SMA/MA" },
    // { value: "SMK/MAK", label: "SMK/MAK" },
  ]);
  const [kelasOpt, setKelasOpt] = useState([]);
  const [rombelOpt, setRombelOpt] = useState([]);
  const [rombelMemberOpt, setRombelMemberOpt] = useState([]);

  const [selectedTahunAjaran, setSelectedTahunAjaran] = useState(null);
  const [selectedJenjang, setSelectedJenjang] = useState(null);
  const [selectedKelas, setSelectedKelas] = useState(null);
  const [selectedRombel, setSelectedRombel] = useState(null);

  const handleSelectedTahunAjaran = (e) => {
    setSelectedTahunAjaran(e);
  }
  const handleSelectedJenjang = (e) => {
    console.log(e.value);
    setSelectedJenjang(e);
    loadKelas(e.value);
  };

  const handleSelectedKelas = (e) => {
    setSelectedKelas(e);
    loadRombel(e.value);
  };

  const handleSelectedRombel = (e) => {
    setSelectedRombel(e);
    loadRombelMember(e.value);
  };

  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].jenjang, label: data[i].jenjang });
      }
      setJenjangOpt(tmp);
    }
  }


  const [peserta, setPeserta] = useState([
    {
      nisn: "927836",
      nama: "Akbar Fauzi",
      jenis_kelamin: "L",
      tgl_lahir: "13/12/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927837",
      nama: "Budi Santoso",
      jenis_kelamin: "L",
      tgl_lahir: "23/01/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927838",
      nama: "Citra Dewi",
      jenis_kelamin: "P",
      tgl_lahir: "15/02/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927839",
      nama: "Dimas Pratama",
      jenis_kelamin: "L",
      tgl_lahir: "07/03/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927840",
      nama: "Eka Saputra",
      jenis_kelamin: "L",
      tgl_lahir: "19/04/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927841",
      nama: "Fitriani",
      jenis_kelamin: "P",
      tgl_lahir: "27/05/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927842",
      nama: "Gilang Ramadhan",
      jenis_kelamin: "L",
      tgl_lahir: "08/06/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927843",
      nama: "Hana Putri",
      jenis_kelamin: "P",
      tgl_lahir: "17/07/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927844",
      nama: "Irfan Maulana",
      jenis_kelamin: "L",
      tgl_lahir: "26/08/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927845",
      nama: "Jasmine Alisha",
      jenis_kelamin: "P",
      tgl_lahir: "04/09/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927846",
      nama: "Kevin Pratama",
      jenis_kelamin: "L",
      tgl_lahir: "13/10/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927847",
      nama: "Larasati",
      jenis_kelamin: "P",
      tgl_lahir: "21/11/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927848",
      nama: "Muhammad Rizki",
      jenis_kelamin: "L",
      tgl_lahir: "30/12/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927849",
      nama: "Nadia Syifa",
      jenis_kelamin: "P",
      tgl_lahir: "09/01/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927850",
      nama: "Omar Prasetyo",
      jenis_kelamin: "L",
      tgl_lahir: "18/02/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927851",
      nama: "Putri Andini",
      jenis_kelamin: "P",
      tgl_lahir: "27/03/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927852",
      nama: "Qori Amanda",
      jenis_kelamin: "P",
      tgl_lahir: "05/04/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927853",
      nama: "Rama Wijaya",
      jenis_kelamin: "L",
      tgl_lahir: "14/05/2003",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927854",
      nama: "Siti Nurhaliza",
      jenis_kelamin: "P",
      tgl_lahir: "23/06/2002",
      wali_kelas: "Dona Wariska",
    },
    {
      nisn: "927855",
      nama: "Teguh Prabowo",
      jenis_kelamin: "L",
      tgl_lahir: "02/07/2003",
      wali_kelas: "Dona Wariska",
    },
  ]);

  const [tahunAjaranOpt, setTahunAjaranOpt] = useState([]);

  const generateTahunAjaranBackward = (currentYear, numberOfYears) => {
    const tahunAjaran = [];
    for (let i = 0; i < numberOfYears; i++) {
        let tahunMulai = currentYear - i - 1; // Go backwards
        let newTahun = tahunMulai + 1
        tahunAjaran.push({value: `${tahunMulai}/${newTahun}`,label: `${tahunMulai}/${newTahun}`});
    }
    return tahunAjaran;
  }

  const loadKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelasOpt(tmp);
    }
  };

  const loadRombel = async (kelas) => {
    var response = await axiosInstance.get("/select/rombel");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i], label: data[i].nama });
      }
      setRombelOpt(tmp);
    }
  };

  const loadRombelMember = async (rombel) => {
    var response = await axiosInstance.get("/select/rombel/"+ rombel.id +"/member");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      if(data.length == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Rombel ini belum ada siswa'
        })
        return;
      }
      for (var i = 0; i < data.length; i++) {
        tmp.push(data[i]);
      }
      setRombelMemberOpt(tmp);
    }
  };

  const getFormData = async () => {
    if (selectedTahunAjaran == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih Tahun Ajaran Terlebih Dahulu'
      })
      return null;
    }

    if(selectedJenjang == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih Jenjang Terlebih Dahulu'
      })
      return null;
    }

    if (selectedKelas == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih Kelas Terlebih Dahulu'
      })
      return null;
    }

    if (selectedRombel == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Pilih Rombel Terlebih Dahulu'
      })
      return null;
    }

    if (rombelMemberOpt.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Rombel tidak ada siswa'
      })
      return null;
    }

    return {
      tahun_ajaran: selectedTahunAjaran,
      jenjang: selectedJenjang,
      kelas: selectedKelas,
      rombel: selectedRombel,
      rombel_member: rombelMemberOpt
    };
  };
  

 useEffect(() => {
    const tahunAjaran = generateTahunAjaranBackward(new Date().getFullYear(), 5);
    setTahunAjaranOpt(tahunAjaran);
    console.log(props.defaultData);
    loadJenjang();
    if(props.defaultData.tahun_ajaran != null) {
      console.log("default data : ", props.defaultData);
      setSelectedTahunAjaran(props.defaultData.tahun_ajaran);
      setSelectedJenjang(props.defaultData.jenjang);
      loadKelas(props.defaultData.jenjang.value);
      setSelectedKelas(props.defaultData.kelas);
      loadRombel(props.defaultData.kelas.value);
      setSelectedRombel(props.defaultData.rombel);
      setRombelMemberOpt(props.defaultData.rombel_member);
      console.log(selectedTahunAjaran);
      console.log(selectedJenjang);
      console.log(selectedKelas);
      console.log(selectedRombel);
    }
  }, [props.defaultData]);

  return (
    <section>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <h5 className="text-primary">
              Tahun Ajaran <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={tahunAjaranOpt}
              value={selectedTahunAjaran}
              placeholder="Pilih Tahun Ajaran"
              onChange={(e) => handleSelectedTahunAjaran(e)}
            />
          </div>
          {/* <div className="form-group mt-3">
            <h5 className="text-primary">
              Semester <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={jenjang[0]}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={jenjang}
            />
          </div> */}
          <div className="form-group mt-3">
            <h5 className="text-primary">
              Jenjang <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={jenjangOpt}
              value={selectedJenjang}
              placeholder="Pilih Jenjang"
              onChange={(e) => handleSelectedJenjang(e)}
            />
          </div>
          {/* <div className="form-group mt-3">
            <h5 className="text-primary">
              Sekolah <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={jenjang[0]}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={jenjang}
            />
          </div> */}
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <h5 className="text-primary">
              Kelas <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              placeholder={selectedJenjang ? "Pilih Kelas" : "Pilih Jenjang Terlebih Dahulu"}
              options={kelasOpt}
              value={selectedKelas}
              onChange={(e) => handleSelectedKelas(e)}
            />
          </div>
          {/* <div className="form-group mt-3">
            <h5 className="text-primary">
              Jurusan <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
            />
          </div> */}
          <div className="form-group mt-3">
            <h5 className="text-primary">
              Rombel <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name="color"
              options={rombelOpt}
              value={selectedRombel}
              placeholder={selectedKelas ? "Pilih Rombel" : "Pilih Kelas Terlebih Dahulu"}
              onChange={(e) => handleSelectedRombel(e)}
            />
          </div>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12">
          <div className="table">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th>No</th>
                    <th>Nama</th>
                    <th>Email</th>
                    <th>JK</th>
                    <th>Tgl Lahir</th>
                  </tr>
                </thead>

                <tbody>
                  {rombelMemberOpt.map((v, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{v.nama}</td>
                      <td>{v.value}</td>
                      <td>{v.jenis_kelamin == 1 ? "Laki-Laki" : "Perempuan"}</td>
                      <td>{v.tanggal_lahir}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default PilihPesertaUjian;
