export const MenuList = [
  {
    title: "Dashboard",
    iconStyle: <i className="material-icons">home</i>,
    to: "dashboard",
  },
  {
    title: "Referensi",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">bookmark</i>,
    content: [
      {
        title: "Struktur Kurikulum",
        to: "referensi/struktur-kurikulum",
      },
      {
        title: "Struktur Rombel",
        to: "referensi/struktur-rombel",
      },
    ],
  },
  {
    title: "Kartu Soal",
    iconStyle: <i className="material-icons">book</i>,
    to: "kartu-soal",
  },
  {
    title: "Paket Soal",
    iconStyle: <i className="material-icons">library_books</i>,
    to: "paket-soal",
  },

  {
    title: "Daftar Ujian",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">list</i>,
    content: [
      {
        title: "Test Online/Tryout",
        to: "daftar-ujian/test-online",
      },
      {
        title: "USBK",
        to: "daftar-ujian/usbk",
      },
    ],
  },
  {
    title: "Hasil Ujian",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">poll</i>,
    content: [
      {
        title: "Test Online",
        to: "hasil-ujian/test-online",
      },
      {
        title: "Tryout",
        to: "hasil-ujian/tryout",
      },
    ],
  },
];
