import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ totalData, dataPerPage, onChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalData / dataPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onChange(pageNumber);  // Call the parent onChange function
  };

  const renderPageNumbers = () => {
    let pages = [];

    // Define startPage and endPage within renderPageNumbers
    const startPage = Math.max(1, currentPage - 5);
    const endPage = Math.min(totalPages, currentPage + 5);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <Link 
            to={"#"} 
            className="page-link" 
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Link>
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="table-pagination teach">
      <small>
        Showing <span>{(currentPage - 1) * dataPerPage + 1}-{Math.min(currentPage * dataPerPage, totalData)}</span> from <span>{totalData}</span> data
      </small>
      <nav>
        <ul className="pagination pagination-gutter pagination-primary no-bg">
          <li className={`page-item page-indicator ${currentPage === 1 ? 'disabled' : ''}`}>
            <Link 
              to={"#"} 
              className="page-link" 
              onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </Link>
          </li>

          {/* Conditionally show the "1" page and an ellipsis if there are pages before the startPage */}
          {currentPage > 6 && (
            <>
              <li className="page-item">
                <Link to={"#"} className="page-link" onClick={() => handlePageChange(1)}>1</Link>
              </li>
              <li className="page-item disabled"><span className="page-link">...</span></li>
            </>
          )}

          {renderPageNumbers()}

          {/* Conditionally show the last page and an ellipsis if there are pages after the endPage */}
          {currentPage < totalPages - 5 && (
            <>
              <li className="page-item disabled"><span className="page-link">...</span></li>
              <li className="page-item">
                <Link to={"#"} className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</Link>
              </li>
            </>
          )}

          <li className={`page-item page-indicator ${currentPage === totalPages ? 'disabled' : ''}`}>
            <Link 
              to={"#"} 
              className="page-link" 
              onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
