import React, { useEffect, useRef, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const SebabAkibat = (props) => {
  console.log("ini props : ", props.jumlahpg);

  const [soal, setSoal] = useState("");
  const [pilihan, setPilihan] = useState({});
  const [selectedOption, setSelectedOption] = useState('');

  const [firstLoad, setFirstLoad] = useState(true);
  const prevSoalValue = useRef();
  useEffect(() => {
    if (props.defaultValue != null && firstLoad) {
      if(!isEqual(prevSoalValue.current, soal)){
        prevSoalValue.current = soal;
        setSoal(props.defaultValue.pertanyaan);

        const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
          const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
          acc[key] = props.defaultValue.pilihan[key];
          return acc;
        }, {});
        setPilihan(newPilihan);
        setSelectedOption(props.defaultValue.jawaban)
      }
    }else{
      const newPilihan = [...Array(props.jumlahpg)].reduce((acc, _, index) => {
        const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
        acc[key] = '';
        return acc;
      }, {});
      setPilihan(newPilihan);
    }
  }, [props.jumlahpg]);

  // Fungsi untuk menangani perubahan pada input teks
  const handleInputChange = (key, value) => {
    setPilihan({
      ...pilihan,
      [key]: value
    });
  };

  // Fungsi untuk menangani perubahan pilihan radio
  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };



  const handleSaveButton = () => {
    var params = {
      "pertanyaan": soal,
      "pilihan": pilihan,
      "jawaban": selectedOption
    };
    props.onSaveButton(params)
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="text text-primary">
            Input Soal <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <CKEditor
              config={{
                placeholder: "Tuliskan soal di sini...",
                extraPlugins: [uploadPlugin],
              }}
              editor={ClassicEditor}
              data={soal}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSoal(data);
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              disabled={props.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-3">
        <div className="col-sm-12">
          <h5 className="text text-primary">
            Pilihan Jawaban <span className="text-danger">*</span>
          </h5>
          {Object.keys(pilihan).map((key, index) => (
            <div className="row mt-2" key={index}>
              <div className="col-1 centered-item">
                <input
                  className="form-check-input ms-4"
                  type="radio"
                  name="gridRadios"
                  value={key}
                  onChange={() => handleRadioChange(key)}
                  checked={selectedOption === key}
                  disabled={props.isDisabled}
                />
              </div>
              <div className="col-8">
                <input type="text" className="form-control"
                placeholder={`Tambahkan opsi pilihan ${key.toUpperCase()} disini.`}
                value={pilihan[key]}
                disabled={props.isDisabled}
                onChange={(e) => handleInputChange(key, e.target.value)} />
              </div>
              <div className="col-3">
                <button className="btn btn-outline-primary w-100">
                  <i className="fas fa-image me-2"></i>
                  Add Media
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {props.isDisabled ? "" : (
      <div className="row mt-3 mb-4">
        <div className="col-sm-12 text-end">
          {/* <button className="btn btn-sm m-1 btn-outline-primary">
            Simpan Draft
          </button> */}
          <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button>
        </div>
      </div>
      )}
    </>
  );
};

export default SebabAkibat;
