import React  from 'react';
import {connect, useDispatch } from 'react-redux';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';

import { Logout } from '../../../store/actions/AuthActions';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';
import { useCookies } from 'react-cookie';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
  return ComponentWithRouterProp;
}

function LogoutPage(props){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cookies,setCookie, removeCookie] = useCookies(['XSRF-TOKEN']);
  

    // axiosInstance.get("auth/logout");

    const onLogout = () => {
      console.log('onLogout');
      removeCookie('XSRF-TOKEN', {path: '/', domain: '.schoolmedia.my.id'})
    // console.log ();
    document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

       dispatch(Logout(navigate));
       // window.location.reload();
    }
    return(
        <>
            <button  className="dropdown-item ai-icon btn btn-primary light" onClick={onLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--primary)" strokeWidth="2" strokeLinecap="round"  strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                <span className="ms-2 text-primar" >Logout </span>
            </button>
        </>
    )
} 
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));