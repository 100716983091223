import React, { useState } from "react";
import HeaderBursa from "./HeaderBursa";
import imageP from "../../../images/background/bg1.png";
import BG from "../../../images/background/vec-a.png";
import FooterBursa from "./FooterBursa";

const LandingPage = () => {
  const [konten, setKonten] = useState([
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
    {
      judul: "Paket Soal Matematika",
      jenis: "Paket Soal",
      jumlah_butir: "50",
      created_by: "Donawariska",
      cover: imageP,
    },
  ]);
  
  const removeCookie = () => {
    console.log("removeCookie");
    document.cookie = "SSO_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=login.schoolmedia.id";

  };
  return (
    <>
      <HeaderBursa />
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <h1 className="text-dark">
              Tempat berbagi materi ajar dan ujian untuk meningkatkan kualitas pembelajaran di sekolah
            </h1>
            <p className="text-s">
              Temukan bermacam paket soal dan kartu soal sesuai jenjang
              pendidikan (PAUD/RA, SD/MI, SMP/MTs, SMA/MA dan SMK/MAK) disini.
            </p>
            <button className="btn btn-outline-primary bg-light mt-2 btn-pill px-2 p-1" onClick={removeCookie}>
              Telusuri{" "}
              <span className="btn btn-primary btn-sm ms-5">
                <i className="fas fa-chevron-right"></i>
              </span>
            </button>
          </div>
          <div className="col-sm-6">
            <center>
              <img src={BG} alt="" />
            </center>
          </div>
        </div>
      </div>
      <div className="ctn-prev-bursa pb-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <center>
                <h1 className="mt-5">Temukan Paket Soal dan Kartu Soal !</h1>
              </center>
            </div>
            <div className="col-sm-12 mt-4">
              <div className="row">
                {konten.map((v, i) => (
                  <>
                    <div className="col-sm-3 mt-4">
                      <div className="card-ctn-landing w-100">
                        <div className="ctn-cover">
                          <img src={v.cover} className="w-100" alt="" />
                        </div>
                        <div className="ctn-v p-3">
                          <h4 className="text-dark">{v.judul}</h4>
                          <div className="d-flex mt-3">
                            <b className="text-dark">
                              <i className="fas fa-book"></i> {v.jenis}
                            </b>
                            <b className="ms-3 text-dark">
                              <i className="fas fa-book"></i> {v.jumlah_butir}{" "}
                              Soal
                            </b>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between flex-wrap">
                            <span>By {v.created_by}</span>
                            <h4>Sc. 50.000,-</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBursa />
    </>
  );
};

export default LandingPage;
