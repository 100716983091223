import React, { useEffect, useImperativeHandle, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";

const SettingUjian = React.forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    getData() {
      return getFormData()
    }
  }))
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [room, setRoom] = useState(null);

  const getFormData = async () => {
    if (selectedDate == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Waktu Ujian harus dipilih'
      })
      return null;
    }

    if(room == null || room == '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ruang Ujian harus diisi'
      })
      return null;
    }

    return {
      started_at: selectedDate,
      ruangan: room
    };
  };

  useEffect(() => {
    
    if(props.defaultData.started_at != null) {
      setSelectedDate(props.defaultData.started_at);
      setRoom(props.defaultData.ruangan);
    }
  }, [props.defaultData]);

  return (
    <section>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <h5 className="text-primary">
              Waktu Ujian <span className="text-danger">*</span>
            </h5>
            <input type="datetime-local" className="form-control" required onChange={(e) => {setSelectedDate(e.target.value)}} value={selectedDate} />
          </div>
          {/* <div className="form-group mt-3">
            <h5 className="text-primary">
              Nama Tempat <span className="text-danger">*</span>
            </h5>
            <input
              type="text"
              placeholder="Nama Tempat"
              className="form-control"
            />
          </div>
          <div className="form-group mt-3">
            <h5 className="text-primary">
              Ruang <span className="text-danger">*</span>
            </h5>
            <input type="text" placeholder="Ruang" className="form-control" />
          </div> */}
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <h5 className="text-primary">
              Ruangan <span className="text-danger">*</span>
            </h5>
            <input
              type="text"
              placeholder="Nama Ruangan"
              className="form-control"
              onChange={(e) => {setRoom(e.target.value)}}
              value={room}
            />
          </div>
          {/* <div className="form-group mt-3">
            <h5 className="text-primary">
              Alamat <span className="text-danger">*</span>
            </h5>
            <textarea name="" className="form-control" placeholder="Alamat" id=""></textarea>
          </div> */}
        </div>
        <div className="col-12">
          <hr />
        </div>
      </div>
    </section>
  );
});

export default SettingUjian;
