import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const StrukturKurikulum = () => {
  const inputEl = useRef(null);

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const [exData, setExData] = useState([
    {
      kode_jenjang: "0",
      jenjang: "PAUD",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "TKK",
      mp: "Taman Kanak-kanak",
      indeks_sk: "0/A/N/TKK",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAI",
      mp: "Pendidikan Agama Islam dan Budi Pekerti",
      indeks_sk: "1/A/A/PAI",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAP",
      mp: "Pendidikan Agama Kristen dan Budi Pekerti",
      indeks_sk: "1/A/A/PAP",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAK",
      mp: "Pendidikan Agama Katholik dan Budi Pekerti",
      indeks_sk: "1/A/A/PAK",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAH",
      mp: "Pendidikan Agama Hindu dan Budi Pekerti",
      indeks_sk: "1/A/A/PAH",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAB",
      mp: "Pendidikan Agama Budha dan Budi Pekerti",
      indeks_sk: "1/A/A/PAB",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAC",
      mp: "Pendidikan Agama Khonghucu dan Budi Pekerti",
      indeks_sk: "1/A/A/PAC",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "BHS",
      mp: "Bahasa Indonesia",
      indeks_sk: "1/A/N/BHS",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "IPA",
      mp: "Ilmu Pengetahuan Alam",
      indeks_sk: "1/A/N/IPA",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "IPS",
      mp: "Ilmu Pengetahuan Sosial",
      indeks_sk: "1/A/N/IPS",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "MAT",
      mp: "Matematika",
      indeks_sk: "1/A/N/MAT",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "PKN",
      mp: "Pendidikan Pancasila dan Kewarganegaraan",
      indeks_sk: "1/A/N/PKN",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "B",
      kmp: "Wajib Lokal",
      kode_jkmp: "L",
      jkmp: "Lokal",
      kode_mp: "PJO",
      mp: "Pendidikan Jasmani, Olah Raga, dan Kesehatan",
      indeks_sk: "1/B/L/PJO",
    },
    {
      kode_jenjang: "1",
      jenjang: "SD/MI",
      kode_kmp: "B",
      kmp: "Wajib Lokal",
      kode_jkmp: "L",
      jkmp: "Lokal",
      kode_mp: "SBK",
      mp: "Seni Budaya dan Prakarya",
      indeks_sk: "1/B/L/SBK",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAI",
      mp: "Pendidikan Agama Islam dan Budi Pekerti",
      indeks_sk: "2/A/A/PAI",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAP",
      mp: "Pendidikan Agama Kristen dan Budi Pekerti",
      indeks_sk: "2/A/A/PAP",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAK",
      mp: "Pendidikan Agama Katholik dan Budi Pekerti",
      indeks_sk: "2/A/A/PAK",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAH",
      mp: "Pendidikan Agama Hindu dan Budi Pekerti",
      indeks_sk: "2/A/A/PAH",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAB",
      mp: "Pendidikan Agama Budha dan Budi Pekerti",
      indeks_sk: "2/A/A/PAB",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "A",
      jkmp: "Agama",
      kode_mp: "PAC",
      mp: "Pendidikan Agama Khonghucu dan Budi Pekerti",
      indeks_sk: "2/A/A/PAC",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "BHS",
      mp: "Bahasa Indonesia",
      indeks_sk: "2/A/N/BHS",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "IPA",
      mp: "Ilmu Pengetahuan Alam",
      indeks_sk: "2/A/N/IPA",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "IPS",
      mp: "Ilmu Pengetahuan Sosial",
      indeks_sk: "2/A/N/IPS",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "MAT",
      mp: "Matematika",
      indeks_sk: "2/A/N/MAT",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "A",
      kmp: "Wajib Nasional",
      kode_jkmp: "N",
      jkmp: "Nasional",
      kode_mp: "PKN",
      mp: "Pendidikan Pancasila dan Kewarganegaraan",
      indeks_sk: "2/A/N/PKN",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "B",
      kmp: "Wajib Lokal",
      kode_jkmp: "L",
      jkmp: "Lokal",
      kode_mp: "PJO",
      mp: "Pendidikan Jasmani, Olah Raga, dan Kesehatan",
      indeks_sk: "2/B/L/PJO",
    },
    {
      kode_jenjang: "2",
      jenjang: "SMP/MTs",
      kode_kmp: "B",
      kmp: "Wajib Lokal",
      kode_jkmp: "L",
      jkmp: "Lokal",
      kode_mp: "SBK",
      mp: "Seni Budaya dan Prakarya",
      indeks_sk: "2/B/L/SBK",
    },
  ]);

  console.log("data");
  console.log(data);

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <div className="col-sm-4">
            <input
              type="search"
              className="form-control"
              placeholder="Cari Struktur Kurikulum ..."
            />
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    <th className="sorting">Kode</th>
                    <th className="sorting">Jenjang</th>
                    <th className="sorting">Kode</th>
                    <th className="sorting">KMP</th>
                    <th className="sorting">Kode</th>
                    <th className="sorting">JKMP</th>
                    <th className="sorting">Kode</th>
                    <th className="sorting">MP</th>
                    <th className="sorting text-center">Indeks Ks</th>
                  </tr>
                </thead>

                <tbody>
                  {exData.map((v, i) => (
                    <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                      <td>{i + 1}</td>
                      <td>{v.kode_jenjang}</td>
                      <td>{v.jenjang}</td>
                      <td>{v.kode_kmp}</td>
                      <td>{v.kmp}</td>
                      <td>{v.kode_jkmp}</td>
                      <td>{v.jkmp}</td>
                      <td>{v.kode_mp}</td>
                      <td>{v.mp}</td>
                      <td>{v.indeks_sk}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/referensi/struktur-kurikulum"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.curreU - 1)
                    }
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/referensi/struktur-kurikulum"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/referensi/struktur-kurikulum"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrukturKurikulum;
