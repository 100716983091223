import React, { useState, useEffect, useRef } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { UploadAdapter, FileLoader } from "@ckeditor/ckeditor5-upload/src/filerepository";
import axiosInstance from "../../../../services/AxiosInstance";
import { isEqual } from "../../../../utils";
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const PilihanGanda = (props) => {
  const [soal, setSoal] = useState([...Array(props.groupsoal)].map((_, index) => ("")));
  const [pilihan, setPilihan] = useState([...Array(props.groupsoal)].map(() => ({})));
  const [selectedOption, setSelectedOption] = useState([...Array(props.groupsoal)].map(() => ''));
  const [narasi, setNarasi] = useState(""); // State untuk narasi soal

  const [firstLoad, setFirstLoad] = useState(true);
  const prevSoalValue = useRef();
  useEffect(() => {
    console.log("PilihanGanda props : ", props);
    console.log("PilihanGanda useEffect : ", soal);

    if (props.defaultValue != null && firstLoad) {
      if(!isEqual(prevSoalValue.current, soal)){
        prevSoalValue.current = soal;
        
        // const newSoal = [...Array(props.groupsoal)].map(() => "");
        // console.log("PilihanGanda newSoal : ", newSoal);
        // setSoal(newSoal);

        if(props.groupsoal != undefined){
          if(props.groupsoal == 1) {
            // handleSoalChange(0, props.defaultValue.pertanyaan);
            const newSoal = [...Array(props.groupsoal)].map((_, groupIndex) => (
              console.log("soal sebelumnya : ", soal[groupIndex]),
              console.log("groupIndex : ", groupIndex),
              console.log("soal : ", props.defaultValue.pertanyaan),
              // setTimeout(() => {
              //   handleSoalChange(groupIndex, props.defaultValue.soal[groupIndex].pertanyaan)
              // }, 100)
              props.defaultValue.pertanyaan
            ))

            setSoal(newSoal);
            
          }else{
            setNarasi(props.defaultValue.content);
            const newSoal = [...Array(props.groupsoal)].map((_, groupIndex) => (
              console.log("soal sebelumnya : ", soal[groupIndex]),
              console.log("groupIndex : ", groupIndex),
              console.log("soal : ", props.defaultValue.soal[groupIndex].pertanyaan),
              // setTimeout(() => {
              //   handleSoalChange(groupIndex, props.defaultValue.soal[groupIndex].pertanyaan)
              // }, 100)
              props.defaultValue.soal[groupIndex].pertanyaan
            ))

            setSoal(newSoal);
            
            console.log("soal semua : ", soal);
          }

        }
      }
      
    }

    if (props.defaultValue == null) {
      const newPilihan = [...Array(props.groupsoal)].map(() =>
        [...Array(props.jumlahpg)].reduce((acc, _, index) => {
          const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
          acc[key] = "";
          return acc;
        }, {})
      );
      
      setPilihan(newPilihan);
    }
  }, [props.jumlahpg, props.groupsoal, props.defaultValue, soal]);

  const prevPilihanValue = useRef();
  useEffect(() => {
    console.log("firstLoad : ", firstLoad);
    if (props.defaultValue != null && firstLoad) {
      if(!isEqual(prevPilihanValue.current, pilihan)){
        prevPilihanValue.current = pilihan;

        if(props.groupsoal != undefined){
          if(props.groupsoal == 1) {
            const newPilihan = [...Array(props.groupsoal)].map(() =>
              [...Array(props.jumlahpg)].reduce((acc, _, index) => {
                const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
                acc[key] = props.defaultValue.pilihan[key];
                return acc;
              }, {})
            );
            
            setPilihan(newPilihan);
            
            const newSelectedOption = [...Array(props.groupsoal)].map((value, index) => props.defaultValue.jawaban);

            setSelectedOption(newSelectedOption)
            // handleRadioChange(0, props.defaultValue.jawaban)
          }else{
            const newPilihan = [...Array(props.groupsoal)].map((soal, soalIndex) =>
              [...Array(props.jumlahpg)].reduce((acc, _, index) => {
                const key = String.fromCharCode(97 + index); // 'a', 'b', 'c', ...
                acc[key] = props.defaultValue.soal[soalIndex].pilihan[key];
                return acc;
              }, {})
            );

            setPilihan(newPilihan);


            const newSelectedOption = [...Array(props.groupsoal)].map((value, index) => props.defaultValue.soal[index].jawaban);
            

            setSelectedOption(newSelectedOption)
          }
        }
        
      }
      
    }
    
  }, [props.groupsoal, pilihan, props.defaultValue, selectedOption])

  const handleInputChange = (groupIndex, key, value) => {
    console.log("handleInputChange triggered : ", groupIndex, key, value);
    setFirstLoad(false);
    const updatedPilihan = [...pilihan];
    updatedPilihan[groupIndex] = {
      ...pilihan[groupIndex],
      [key]: value,
    };
    setPilihan(updatedPilihan);
  };

  const handleRadioChange = (groupIndex, value) => {
    setFirstLoad(false);
    const updatedSelectedOption = [...selectedOption];
    updatedSelectedOption[groupIndex] = value;
    setSelectedOption(updatedSelectedOption);
  };

  const handleSoalChange = (groupIndex, value) => {
    setFirstLoad(false);
    const updatedSoal = [...soal];
    updatedSoal[groupIndex] = value;
    setSoal(updatedSoal);
  };

  const handleSaveButton = () => {
    // Menghasilkan daftar soal
    const soalParams = soal.map((pertanyaan, index) => ({
      "pertanyaan": pertanyaan,
      "pilihan": pilihan[index],
      "jawaban": selectedOption[index]
    }));

    // Jika groupsoal lebih dari 1, tambahkan narasi
    const params = props.groupsoal > 1
      ? {
          "content": narasi, // Narasi soal
          "soal": soalParams
        }
      : soalParams[0];

    // Mengirim params ke onSaveButton props
    props.onSaveButton(params);
  };

  return (
    <>
      {props.groupsoal > 1 ? (
        <div className="row">
          <div className="col-xl-12 mt-2">
            <h5 className="text text-primary">
              Narasi Soal <span className="text-danger">*</span>
            </h5>
            <CKEditor
              config={{
                placeholder: "Tuliskan narasi soal di sini...",
              }}
              editor={ClassicEditor}
              data={narasi}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNarasi(data); // Set narasi soal
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {[...Array(props.groupsoal)].map((_, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="row mt-5">
            <div className="col-sm-12">
              <h5 className="text text-primary">
                Input Soal<span className="text-danger">*</span>
              </h5>
              <div className="col-xl-12 mt-2">
                <CKEditor
                  config={{
                    // plugins: [PasteFromOffice],
                    placeholder: "Tuliskan soal di sini...",
                    extraPlugins: [uploadPlugin],
                    // toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo']
                  }}
                  editor={ClassicEditor}
                  data={soal[groupIndex]}
                  disabled={props.isDisabled}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    handleSoalChange(groupIndex, data);
                  }}
                  // onChange={(event, editor) => {
                  //   const data = editor.getData();
                  //   handleSoalChange(groupIndex, data);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col-sm-12">
              <h5 className="text text-primary">
                Pilihan Jawaban <span className="text-danger">*</span>
              </h5>
              {pilihan[groupIndex] == null ? '' : Object.entries(pilihan[groupIndex]).map(([key, value], index) => (
                <div className="row mt-2" key={index}>
                  <div className="col-1 centered-item">
                    <input
                      className="form-check-input ms-4"
                      type="radio"
                      name={`gridRadios-${groupIndex}`}
                      value={key}
                      onChange={() => handleRadioChange(groupIndex, key)}
                      checked={selectedOption[groupIndex] === key}
                      disabled={props.isDisabled}
                    />
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Tambahkan opsi pilihan ${key.toUpperCase()} disini.`}
                      value={pilihan[groupIndex][key]}
                      // onBlur={(e) => handleInputChange(groupIndex, key, e.target.value)}
                      onChange={(e) => handleInputChange(groupIndex, key, e.target.value)}
                      disabled={props.isDisabled}
                    />
                  </div>
                  <div className="col-3">
                    <button className="btn btn-outline-primary w-100">
                      <i className="fas fa-image me-2"></i>
                      Add Media
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}

      {props.isDisabled ? "" : (
      <div className="row mt-3 mb-4">
        <div className="col-sm-12 text-end">
          {/* <button className="btn btn-sm m-1 btn-outline-primary">
            Simpan Draft
          </button> */}
          <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button>
        </div>
      </div>
      )}
    </>
  );
};

export default PilihanGanda;
