import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";

const StatistikPaketSoal = () => {
  const inputEl = useRef(null);

  const { id_kartu } = useParams();

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const getLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ?? null;
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const [dataSoal, setDataSoal] = useState([
    { id: 1, tipe: "pilihan-ganda" },
    { id: 2, tipe: "pilihan-ganda" },
    { id: 3, tipe: "pilihan-ganda" },
    { id: 4, tipe: "pilihan-ganda" },
    { id: 5, tipe: "pilihan-ganda" },
    { id: 6, tipe: "pilihan-ganda" },
    { id: 7, tipe: "pilihan-ganda" },
    { id: 8, tipe: "pilihan-ganda" },
    { id: 9, tipe: "pilihan-ganda" },
    { id: 10, tipe: "pilihan-ganda" },
    { id: 11, tipe: "pilihan-ganda" },
    { id: 12, tipe: "pilihan-ganda" },
    { id: 13, tipe: "pilihan-ganda" },
    { id: 14, tipe: "pilihan-ganda" },
    { id: 15, tipe: "pilihan-ganda" },
    { id: 16, tipe: "pilihan-ganda" },
    { id: 17, tipe: "pilihan-ganda" },
    { id: 18, tipe: "pilihan-ganda" },
    { id: 19, tipe: "pilihan-ganda" },
    { id: 20, tipe: "pilihan-ganda" },
  ]);

  const [dataNilai, setDataNilai] = useState([
    {
      nama_peserta: "Ahmad Riyadi",
      nilai_total: 84,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Budi Santoso",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
      ],
    },
    {
      nama_peserta: "Citra Dewi",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Dian Lestari",
      nilai_total: 92,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Eka Putra",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    // Tambahkan 15 data lainnya sesuai pola di atas
    {
      nama_peserta: "Farhan Hakim",
      nilai_total: 72,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Gilang Ramadhan",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Hana Setiawan",
      nilai_total: 82,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Ilham Pratama",
      nilai_total: 70,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Joko Purwanto",
      nilai_total: 74,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Kurniawan Saputra",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Linda Putri",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Maya Sari",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Nadia Maulida",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
      ],
    },
    {
      nama_peserta: "Omar Sulaiman",
      nilai_total: 86,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
  ]);

  const sort = 100;
  const activePag = useRef(0);

  const [showPage, setShowPage] = useState(
    getLocalStorage("showPage") ?? "jawaban"
  );

  const [showKomposisi, setShowKomposisi] = useState(false);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleShow = (param) => {
    setLocalStorage("showPage", param);
    setShowPage(param);
  };
  const handleShowKomposisi = () => {
    setShowKomposisi(!showKomposisi);
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  //Load data statistik
  const [statistik, setStatistik] = useState(null);
  useEffect(() => {
    getStatistik(id_kartu).then((res) => {
      setStatistik(res);
    });
  }, [id_kartu]);

  const getStatistik = async (id_kartu) => {
    return await axiosInstance.get(`statistik?code=${id_kartu}&type=paket-soal`);
  };

  const ChartStatistic = loadable(() =>
    pMinDelay(import("./ChartStatistic"), 1000)
 );

  return (
    <>
    <div className="col-12">
      <div className="card">
        <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Statistik Butir Soal</div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12">
    <div className="card">
      <div className="">
        <div>
          {statistik &&  <ChartStatistic id_kartu={id_kartu} statistik={statistik.data.data} /> }
        </div>
      </div>
    </div>
  </div>
  </>
    // <div className="col-12">
    //   <div className="card">
    //     <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
    //       <div>
    //         <div className="card-title">Statistik Butir Soal</div>
    //       </div>
    //       <div>
    //         <span>
    //           <i
    //             className={`fas fa-chevron-${showKomposisi ? "up" : "down"}`}
    //           ></i>
    //         </span>
    //       </div>
    //     </div>
    //     <div className="card-body">
    //       <div className="row">
    //         <div className="col-12">
    //           <table className="w-100 table">
    //             <tr>
    //               <td className="text-dark">
    //                 <b>ID Kartu Soal</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>{id_kartu}</b>
    //               </td>
    //             </tr>
    //             <tr>
    //               <td className="text-dark">
    //                 <b>Judul Kartu Soal</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>Soal Bahasa Indonesia</b>
    //               </td>
    //             </tr>
    //             <tr>
    //               <td className="text-dark">
    //                 <b>Jenjang</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>SMA / MA</b>
    //               </td>
    //             </tr>
    //             <tr>
    //               <td className="text-dark">
    //                 <b>Kelas</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>X</b>
    //               </td>
    //             </tr>
    //             <tr>
    //               <td className="text-dark">
    //                 <b>Jurusan</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>MIPA</b>
    //               </td>
    //             </tr>
    //             <tr>
    //               <td className="text-dark">
    //                 <b>Mata Pelajaran</b>
    //               </td>
    //               <td className="text-dark text-end">
    //                 <b>Bahasa Indonesia</b>
    //               </td>
    //             </tr>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="card dz-card">
    //     <div className="card-body">
    //       <div className="table-responsive">
    //         <div id="job_data" className="table">
    //           <table
    //             className="display w-100 table-ex dataTable table-bordered"
    //             id="example5"
    //             role="grid"
    //             aria-describedby="example5_info"
    //           >
    //             <thead className="th-a">
    //               <tr role="row">
    //                 <th className="text-center">No</th>
    //                 <th className="text-center" colSpan={3}>
    //                   Jumlah Pengguna
    //                 </th>
    //                 <th className="text-center" colSpan={5}>
    //                   Jumlah Penjawab
    //                 </th>
    //                 <th className="text-center" colSpan={5}>
    //                   Probabilitas Jawaban
    //                 </th>
    //                 <th className="text-center" colSpan={2}>
    //                   Statistik Butir
    //                 </th>
    //                 <th className="text-center" colSpan={3}>
    //                   Statistik Pilihan Jawaban
    //                 </th>
    //                 <th className="text-center" rowSpan={2}>
    //                   Kesimpulan
    //                 </th>
    //               </tr>

    //               <tr>
    //                 <th>#</th>
    //                 <th>Sekolah</th>
    //                 <th>Rombel</th>
    //                 <th>Siswa</th>
    //                 <th>A</th>
    //                 <th>B</th>
    //                 <th>C</th>
    //                 <th>D</th>
    //                 <th>E</th>
    //                 <th>A</th>
    //                 <th>B</th>
    //                 <th>C</th>
    //                 <th>D</th>
    //                 <th>E</th>
    //                 <th>TK</th>
    //                 <th>DP</th>
    //                 <th>Kunci</th>
    //                 <th>Option</th>
    //                 <th>DP</th>
    //               </tr>
    //             </thead>

    //             <tbody>
    //               <tr>
    //                 <td>1</td>
    //                 <td>SMAN 1 Schoolmedia</td>
    //                 <td>X MIPA 1</td>
    //                 <td>32</td>
    //                 <td>7</td>
    //                 <td>6</td>
    //                 <td>5</td>
    //                 <td>9</td>
    //                 <td>5</td>
    //                 <td>0.2</td>
    //                 <td>0.3</td>
    //                 <td>0.2</td>
    //                 <td>0.1</td>
    //                 <td>0.17</td>
    //                 <td>0.3</td>
    //                 <td>0.3</td>
    //                 <td></td>
    //                 <td></td>
    //                 <td></td>
    //                 <td>-</td>
    //               </tr>
    //               <tr>
    //                 <td>2</td>
    //                 <td>SMAN 1 Schoolmedia</td>
    //                 <td>X MIPA 2</td>
    //                 <td>32</td>
    //                 <td>7</td>
    //                 <td>6</td>
    //                 <td>5</td>
    //                 <td>9</td>
    //                 <td>5</td>
    //                 <td>0.2</td>
    //                 <td>0.3</td>
    //                 <td>0.2</td>
    //                 <td>0.1</td>
    //                 <td>0.17</td>
    //                 <td>0.3</td>
    //                 <td>0.3</td>
    //                 <td></td>
    //                 <td></td>
    //                 <td></td>
    //                 <td>-</td>
    //               </tr>

    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default StatistikPaketSoal;
