import React, { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { Navigate, useNavigate } from "react-router-dom";
import Pagination from "../Pagination";

const AddPaketSoal = () => {
  const navigate = useNavigate();
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");


  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const [elemenSoal, setElemenSoal] = useState([]);

  const [kelasOpt, setKelasOpt] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);

  const [active, setActive] = useState("pilihan-ganda");
  const [showBtnAdd, setShowBtnAdd] = useState(true);

  const [jurusanOpt, setJurusanOpt] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState(null);

  const [typeOpt, setTypeOpt] = useState([
    { value: "test-online/usbk", label: "Test Online/USBK" },
    { value: "tryout", label: "Tryout" },
  ])

  const [selectedType, setSelectedType] = useState(null);

  const [jenjangOpt, setJenjangOpt] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);
  const [selectedJenjang, setselectedJenjang] = useState(null);

  const [semesterOpt, setSemesterOpt] = useState([
    { value: "ganjil", label: "Ganjil" },
    { value: "genap", label: "Genap" },
  ])
  const [selectedSemester, setSelectedSemester] = useState(null);

  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);

  const [duration, setDuration] = useState(0);
  const [title, setTitle] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [arrMataPelajaran, setArrMataPelajaran] = useState([]);

  const [arrButirSoal, setArrButirSoal] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // loadMataPelajaran();
    loadJenjang();
    getListSoal();
  }, [currentPage]);
  const getListSoal = async () => {
    const res = await axiosInstance.get("soal?page=" + currentPage + "&per_page=" + perPage + "&materi=" + searchKeyword);

    if (res.data) {
      var soal = res.data.data;
      console.log("soal:", soal);
      var tmp = []
      for (var i = 0; i < soal.length; i++) {
        tmp.push({
          id: soal[i].id,
          judul: soal[i].detail.materi,
          kode_butir: soal[i].code,
          tipe_soal: getTypeName(soal[i].type),
          kelas: soal[i].detail.kelas,
          type_style: typeByJenisSoal(soal[i].type),
        },);
      }

      setArrButirSoal(tmp);
      setTotal(res.data.total);
      setCurrentPage(res.data.current_page);
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      case "MPG":
        return "pilihan-ganda";
      default:
        return "PG";
    }
  };

  const [mataPelajaranOpt, setMataPelajaranOpt] = useState([]);
  const loadMataPelajaran = async (jenisKelompok) => {
    var response = await axiosInstance.get("select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id="+ jenisKelompok.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMataPelajaranOpt(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };

  const onPaging = (page) => {
    // getListSoal();
    setCurrentPage(page);
  }

  const [arrSelectedSoal, setArrSelectedSoal] = useState([]);


  const [activeTabs, setActiveTabs] = useState([]); // Menggunakan array untuk menyimpan tab aktif
  const [formValues, setFormValues] = useState({
    PG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    MPG: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    SA: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    UR: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    PJ: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
    BS: { jumlah_soal: 0, bobot: 0, point_benar: 0, point_salah: 0, point_kosong: 0 },
  });

  const [errors, setErrors] = useState({
    PG: {},
    MPG: {},
    SA: {},
    UR: {},
    PJ: {},
    BS: {},
  });

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    console.log("Title :", title);

    if(selectedType == null){
      isValid = false;
      newErrors['platform'] = "Platform is required";
    }
    if(title == null){
      isValid = false;
      newErrors['title'] = "Judul is required";
    }
    if(selectedJenjang == null){
      isValid = false;
      newErrors['jenjang'] = "Jenjang is required";
    }

    if(selectedMataPelajaran == null){
      isValid = false;
      newErrors['mapel'] = "Mata Pelajaran is required";
    }

    if(selectedKelas == null){
      isValid = false;
      newErrors['kelas'] = "Kelas is required";
    }

    if(selectedJurusan == null){
      isValid = false;
      newErrors['jurusan'] = "Jurusan is required";
    }

    if(selectedSemester == null){
      isValid = false;
      newErrors['semester'] = "Semester is required";
    }

    if(duration == 0){
      isValid = false;
      newErrors['duration'] = "Duration is required";
    }

    if(indicator == null){
      isValid = false;
      newErrors['indicator'] = "Indikator is required";
    }
    activeTabs.forEach((tab) => {
      newErrors[tab] = {};
      Object.keys(formValues[tab]).forEach((key) => {
        if (!formValues[tab][key]) {
          isValid = false;
          newErrors[tab][key] = `${key} is required`;
        }
      });
    });

    setErrors(newErrors);
    return isValid;
  };

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleActive = (tab) => {
    setActiveTabs((prevActiveTabs) =>
      prevActiveTabs.includes(tab)
        ? prevActiveTabs.filter((t) => t !== tab) // Jika sudah aktif, nonaktifkan
        : [...prevActiveTabs, tab] // Jika belum aktif, tambahkan ke tab aktif
    );
  };

  const handleInput = (e, tab, key) => {
    const { value } = e.target;
    setFormValues({
      ...formValues,
      [tab]: {
        ...formValues[tab],
        [key]: value,
      },
    });
  };

  const generateScore = () => {
    const score = {
      PG: formValues.PG,
      MPG: formValues.MPG,
      SA: formValues.SA,
      UR: formValues.UR,
      PJ: formValues.PJ,
      BS: formValues.BS,
    };
    console.log("Generated score:", score);
    return score;
  };

  const handleSelectJenjang = (e) => {
    if (e) {
      setSelectedKelas(null);
      // if (e.value == "PAUD") {
      //   setKelasOpt([{ value: "0", label: "0" }]);
      // } else if (e.value == "SD/MI") {
      //   setKelasOpt([
      //     { value: "I", label: "I" },
      //     { value: "II", label: "II" },
      //     { value: "III", label: "III" },
      //     { value: "IV", label: "IV" },
      //     { value: "V", label: "V" },
      //     { value: "VI", label: "VI" },
      //   ]);
      // } else if (e.value == "SMP/MTs") {
      //   setKelasOpt([
      //     { value: "VII", label: "VII" },
      //     { value: "VIII", label: "VIII" },
      //     { value: "IX", label: "IX" },
      //   ]);
      // } else if (e.value == "SMA/MA") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // } else if (e.value == "SMK/MAK") {
      //   setKelasOpt([
      //     { value: "X", label: "X" },
      //     { value: "XI", label: "XI" },
      //     { value: "XII", label: "XII" },
      //   ]);
      // }
      setselectedJenjang(e.value);
      getJurusan(e.value);
      getKelas(e.value);
      loadKelompokMataPelajaran(e);
    }
  };


  const loadJenjang = async () => {
    var response = await axiosInstance.get("select/jenjang");
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenjang data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({id: data[i].id, value: data[i].jenjang, label: data[i].jenjang });
      }
      setJenjangOpt(tmp);
    }
  }

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] = useState(null);

  const loadKelompokMataPelajaran = async (jenjang) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang);
    var response = await axiosInstance.get("select/kelompok-mata-pelajaran?jenjang_id="+ jenjang.id);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const handleSelectKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedKelompokMataPelajaran(e);
      loadJenisKelompokMataPelajaran(e);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] = useState([]);
  const [selectedJenisKelompokMataPelajaran, setSelectedJenisKelompokMataPelajaran] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get("select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id="+ kelompok.value);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  }

  const handleSelectJenisKelompokMataPelajaran = (e) => {
    if (e) {
      setSelectedJenisKelompokMataPelajaran(e);
      loadMataPelajaran(e);
    }
  };
  
  const handleSelectedSoal = (e) => {
    console.log("handleSelectedSoal", e);
      setArrSelectedSoal([...arrSelectedSoal, e]);
  }

  const handleNext = () => {
    if (validateForm()) {
      const score = {
        PG: formValues.PG,
        MPG: formValues.MPG,
        SA: formValues.SA,
        UR: formValues.UR,
        PJ: formValues.PJ,
        BS: formValues.BS,
      };
      console.log("Generated score:", score);

      setElemenSoal(score);
      setStep("next");
    } else {
      console.log("Form validation failed.");
      var text = '';
      for (const [key, value] of Object.entries(errors)) {
        console.log(key, value);
        if (typeof value === 'object') {
          text += "<b>" + getTypeName(key) + "</b><br/>";
          for (const [k, v] of Object.entries(value)) {
            console.log(k, v);
            text += v + "<br/>";
          }
        }else{
          text += value + "<br/>";
        }
      }
      Swal.fire({
        title: "Oops !",
        html: text,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const handleFormChange = (value, type) => {
    if (type == "type") {
      setSelectedType(value);
    }
    if (type == "title") {
      setTitle(value);
    }

    if (type == "matapelajaran") {
      setSelectedMataPelajaran(value);
    }

    if (type == "kelas") {
      setSelectedKelas(value);
    }

    if (type == "jurusan") {
      setSelectedJurusan(value);
    }

    if (type == "semester") {
      setSelectedSemester(value);
    }

    if (type == "duration") {
      setDuration(value);
    }

    if (type == "indicator") {
      setIndicator(value);
    }
  };

  function capitalizeWords(text) {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get("/select/jurusan?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusanOpt(tmp);
    }
  };

  const getKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelasOpt(tmp);
    }
  };

  const handleSave = async () => {
    if (arrSelectedSoal.length == 0) {
      Swal.fire({
        title: "Oops !",
        text: "Pilih minimal 1 soal",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
      return;
    }
    var params = {
      "type": selectedType,
      "detail": {
          "judul": title,
          "jenjang": selectedJenjang.split("/")[0],
          "kelas": selectedKelas,
          "jurusan": selectedJurusan,
          "semester": selectedSemester,
          "kelompok_mata_pelajaran": selectedKelompokMataPelajaran.label,
          "jenis_kelompok_mata_pelajaran": selectedJenisKelompokMataPelajaran.label,
          "mata_pelajaran": selectedMataPelajaran.label,
          "durasi": duration * 60,
          "indikator": indicator,
          "image": null
      },
      "score": elemenSoal,
      "soal_id": arrSelectedSoal.map((soal) => soal.id),
    }
    
    console.log("params:", params);
    // return;
    var res = await axiosInstance.post("/paket-soal", params);
    if (res.data.success) {
      Swal.fire({
        title: "Success !",
        text: "Paket Soal Berhasil",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
      console.log("res:", res.data);
      navigate("/paket-soal");
    }else{
      Swal.fire({
        title: "Oops !",
        text: res.data.message,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      })
    }
  }

  const goTo = (action, contact) => {
    console.log("goTo", contact);
    // navigate(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`);
    window.open(`/kartu-soal/${action}/${contact.type_style}?id=${contact.id}`, '_blank');
  }
  
  return (
    <>
      {step == "first" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Tambah Paket Soal</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <h5 className="text-primary">
                        Judul <span className="text-danger">*</span>
                      </h5>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Judul Paket"
                        onChange={(e) => handleFormChange(e.target.value, "title")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenjang <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jenjangOpt}
                        onChange={handleSelectJenjang}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={handleSelectKelompokMataPelajaran}
                          value={selectedKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Jenis Kelompok Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={handleSelectJenisKelompokMataPelajaran}
                          value={selectedJenisKelompokMataPelajaran}
                        />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Mata Pelajaran <span className="text-danger">*</span>
                      </h5>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan Mata Pelajaran"
                        onChange={(e) => handleFormChange(e.target.value, "matapelajaran")}
                      /> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={mataPelajaranOpt[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={mataPelajaranOpt}
                        onChange={(e) => handleFormChange(e, "matapelajaran")}
                      />
                    </div>
                    
                  </div>

                  <div className="col-sm-6">
                  <div className="form-group">
                      <h5 className="text-primary">
                        Kelas <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={kelasOpt}
                        onChange={(e) => handleFormChange(e.value, "kelas")}
                      />
                    </div>
                    <div className="form-group  mt-3">
                      <h5 className="text-primary">
                        Jurusan <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={jurusanOpt}
                        onChange={(e) => handleFormChange(e.value, "jurusan")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Semester <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={semesterOpt}
                        onChange={(e) => handleFormChange(e.value, "semester")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Durasi (Menit)<span className="text-danger">*</span>
                      </h5>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Masukkan durasi"
                        onChange={(e) => handleFormChange(e.target.value, "duration")}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Platform <span className="text-danger">*</span>
                      </h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={nullSel[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        options={typeOpt}
                        onChange={(e) => handleFormChange(e.value, "type")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group mt-3">
                      <h5 className="text-primary">
                        Indikator <span className="text-danger">*</span>
                      </h5>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        placeholder="Masukkan indikator paket soal"
                        onChange={(e) => handleFormChange(e.target.value, "indicator")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                      <div className="col-2 text-center">
                        <h5 className="text-primary mt-1">
                          Komposisi Paket Soal
                        </h5>
                      </div>
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-between flex-wrap mt-3">
                  <ul className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
                    {["PG", "MPG", "SA", "UR", "PJ", "BS"].map((tab, idx) => (
                      <li key={idx} className="nav-item">
                        <button
                          onClick={() => handleActive(tab)}
                          className={`nav-link m-1 btn-outline-primary btn-sm ${activeTabs.includes(tab) ? "active" : null}`}
                          type="button"
                        >
                          {getTypeName(tab)}
                        </button>
                      </li>
                    ))}
                  </ul>
                  </div>
                  <div className="col-sm-12 mt-2">
        {activeTabs.map((tab, idx) => (
          <div key={idx} className="row mt-3">
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Jumlah Soal ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].jumlah_soal}
                  onChange={(e) => handleInput(e, tab, "jumlah_soal")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Bobot ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].bobot}
                  onChange={(e) => handleInput(e, tab, "bobot")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Benar ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_benar}
                  onChange={(e) => handleInput(e, tab, "point_benar")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Salah ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_salah}
                  onChange={(e) => handleInput(e, tab, "point_salah")}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <h5 className={`text-primary text-bold`}>
                  Poin Tidak Jawab ({tab.toUpperCase()})
                </h5>
                <input
                  type="text"
                  className={`form-control border-primary`}
                  value={formValues[tab].point_kosong}
                  onChange={(e) => handleInput(e, tab, "point_kosong")}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="row mt-5">
                      <div className="col-12 text-end">
                        <button
                          type="button"
                          onClick={handleNext}
                          className="btn btn-primary mt-3"
                        >
                          Selanjutnya <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>

      </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-12">
                <div className="card card-c-left">
                  <div className="card-header">
                    <h5>Paket Soal</h5>
                  </div>
                  <div className="card-body table-responsive">
                    <table className="table table-responsive table-bordered w-100">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Bentuk Soal</th>
                          <th>Kode Soal</th>
                          <th>Judul Soal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {arrSelectedSoal.map((v, i) => (
                          <>
                            <tr
                              // onClick={() => handleTableActive(i)}
                              // className={`${
                              //   i == tableActive ? "bg-bl text-dark" : ""
                              // } table-touch`}
                            >
                              <th>{i + 1}</th>
                              <td>{v.tipe_soal}</td>
                              <td>
                                {v.kode_butir || (
                                  <span className="color-secondary w-100 text-center">
                                    -
                                  </span>
                                )}
                              </td>
                              <td>
                                {v.judul || (
                                  <span className="color-secondary w-100 text-center">
                                    -
                                  </span>
                                )}
                              </td>
                              <td>
                                {v ? (
                                  <>
                                    <button className="btn btn-sm rounded-pill btn-outline-primary" onClick={() => goTo('detail', v)}>
                                      Detail
                                    </button>
                                  </>
                                ) : (
                                  <span className="color-secondary w-100 text-center">
                                    -
                                  </span>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>

                    <div className="col-sm-12 text-end">
                      <button className="btn btn-sm m-1 btn-outline-primary">
                        Simpan Draft
                      </button>
                      <button className="btn btn-sm m-1 btn-primary" onClick={handleSave}>
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              {/* <div className="col-12">
                <div className="card">
                  <div
                    onClick={() => setShowFilter(!showFilter)}
                    className="card-header card-header-komposisi d-flex justify-content-between flex-wrap"
                  >
                    <div>
                      <div className="card-title">
                        {" "}
                        <i className="fas fa-filter"></i> Filter
                      </div>
                    </div>
                    <div>
                      <span>
                        <i
                          className={`fas fa-chevron-${
                            showFilter ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {showFilter ? (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jenjang <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={jenjang[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={jenjang}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Mata Pelajaran{" "}
                              <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Kelas <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <h5 className="text-primary">
                              Jurusan <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                          <div className="form-group mt-3">
                            <h5 className="text-primary">
                              Semester <span className="text-danger">*</span>
                            </h5>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={nullSel[0]}
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={nullSel}
                              onChange=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div> */}
              <div className="col-12">
                <div className="card  card-sel-soal">
                  <div className="card-header">
                    <div className="row">
                     <div className="col-10">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Cari nama butir atau kode butir"
                        onChange={(e) => setSearchKeyword(e.target.value)}
                      />
                     </div>
                     <div className="col-1">
                      <button className="btn btn-sm btn-primary" onClick={getListSoal}>
                          Cari
                        </button>
                     </div>
                    </div>
                    
                  </div>
                  <div className="card-body p-0 m-3">
                    <div className="row">
                      {arrButirSoal.map((v, i) => (
                        <div className="col-12 pb-0 mb-0">
                          <>
                            <div className="mt-2 rounded pb-0 card-custom border">
                              <div className="card-header row">
                                <div className="col-9">
                                  <h5>{v.judul}</h5>
                                  <p>{v.kode_butir}</p>
                                </div>
                                <div className="col-3">
                                  <button
                                    type="button"
                                    onClick={() => handleSelectedSoal(v)}
                                    className="btn mb-1 btn-outline-primary btn-sm rounded-circle"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row mb-0 mt-3">
                                  <div className="col-4"> Tipe Soal</div>
                                  <div className="col-8"> : {v.tipe_soal}</div>
                                </div>

                                <div className="row">
                                  <div className="col-4"> Kelas</div>
                                  <div className="col-8"> : {v.kelas}</div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      ))}
                    </div>
                    <Pagination totalData={total} dataPerPage={perPage} onChange={onPaging}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPaketSoal;
