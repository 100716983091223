import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const StrukturRombel = () => {
  const inputEl = useRef(null);

  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");

  const [jenjang, setJenjang] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const [exData, setExData] = useState([
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "MIPA", rombel: "X MIPA 1", jumlah: "32", wali_kelas: "Donawariska" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "IPS", rombel: "XI IPS 2", jumlah: "30", wali_kelas: "Rangga" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "Bahasa", rombel: "XII Bahasa 1", jumlah: "28", wali_kelas: "Sari" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "IPS", rombel: "X IPS 1", jumlah: "31", wali_kelas: "Andi" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "MIPA", rombel: "XI MIPA 3", jumlah: "29", wali_kelas: "Budi" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "MIPA", rombel: "XII MIPA 2", jumlah: "30", wali_kelas: "Cici" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "Bahasa", rombel: "X Bahasa 2", jumlah: "27", wali_kelas: "Dewi" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "IPS", rombel: "XI IPS 3", jumlah: "32", wali_kelas: "Eka" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "Bahasa", rombel: "XII Bahasa 2", jumlah: "26", wali_kelas: "Fajar" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "MIPA", rombel: "X MIPA 2", jumlah: "33", wali_kelas: "Gita" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "Bahasa", rombel: "XI Bahasa 1", jumlah: "28", wali_kelas: "Hadi" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "IPS", rombel: "XII IPS 2", jumlah: "29", wali_kelas: "Ika" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "Bahasa", rombel: "X Bahasa 1", jumlah: "31", wali_kelas: "Joko" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "MIPA", rombel: "XI MIPA 2", jumlah: "30", wali_kelas: "Kiki" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "MIPA", rombel: "XII MIPA 3", jumlah: "32", wali_kelas: "Lina" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "IPS", rombel: "X IPS 3", jumlah: "30", wali_kelas: "Maya" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "Bahasa", rombel: "XI Bahasa 2", jumlah: "27", wali_kelas: "Nina" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "Bahasa", rombel: "XII Bahasa 1", jumlah: "28", wali_kelas: "Omar" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "MIPA", rombel: "X MIPA 3", jumlah: "34", wali_kelas: "Putu" },
    { tahun_ajaran: "2024 / 2025", kelas: "XI", jurusan: "IPS", rombel: "XI IPS 1", jumlah: "31", wali_kelas: "Qori" },
    { tahun_ajaran: "2024 / 2025", kelas: "XII", jurusan: "IPS", rombel: "XII IPS 3", jumlah: "29", wali_kelas: "Rina" },
    { tahun_ajaran: "2024 / 2025", kelas: "X", jurusan: "Bahasa", rombel: "X Bahasa 3", jumlah: "26", wali_kelas: "Sita" },
  ]);
  

  console.log("data");
  console.log(data);

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  return (
    <div className="row">
      <div className="col-12 mt-4">
        <div className="card">
          <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
            <div>
              <div className="card-title">
                {" "}
                <i className="fas fa-filter"></i> Filter
              </div>
            </div>
            <div>
              <span>
                <i className={`fas fa-chevron-down`}></i>
              </span>
            </div>
          </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <h5 className="text-primary">
                      Tahun Ajaran <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={nullSel[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={nullSel}
                      onChange=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <h5 className="text-primary">
                      Jenjang <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={jenjang[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={jenjang}
                      onChange=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <h5 className="text-primary">
                      Mata Pelajaran <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={nullSel[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={nullSel}
                      onChange=""
                    />
                  </div>
                  
                </div>

                <div className="col-sm-6">
                <div className="form-group">
                    <h5 className="text-primary">
                      Kelas <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={nullSel[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={nullSel}
                      onChange=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <h5 className="text-primary">
                      Jurusan <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={nullSel[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={nullSel}
                      onChange=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <h5 className="text-primary">
                      Semester <span className="text-danger">*</span>
                    </h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={nullSel[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={nullSel}
                      onChange=""
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="col-sm-4">
              <input
                type="search"
                className="form-control"
                placeholder="Cari Struktur Rombel ..."
              />
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div id="job_data" className="dataTables_wrapper">
                <table
                  className="display w-100 dataTable table-bordered"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting_asc">No</th>
                      <th className="sorting">Tahun Ajaran</th>
                      <th className="sorting">Kelas</th>
                      <th className="sorting">Jurusan</th>
                      <th className="sorting">Rombel</th>
                      <th className="sorting">Jumlah</th>
                      <th className="sorting">Walikelas</th>
                      <th className="sorting">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {exData.map((v, i) => (
                      <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                        <td>{i + 1}</td>
                        <td>{v.tahun_ajaran}</td>
                        <td>{v.kelas}</td>
                        <td>{v.jurusan}</td>
                        <td>{v.rombel}</td>
                        <td>{v.jumlah}</td>
                        <td>{v.wali_kelas}</td>
                        <td><button className="btn btn-outline-primary btn-sm"><i className="fas fa-eye"></i></button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                  <div className="dataTables_info">
                    Showing {activePag.current * sort + 1} to{" "}
                    {data.length > (activePag.current + 1) * sort
                      ? (activePag.current + 1) * sort
                      : data.length}{" "}
                    of {data.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="/referensi/struktur-rombel"
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.curreU - 1)
                      }
                    >
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to="/referensi/struktur-rombel"
                          className={`paginate_button  ${
                            activePag.current === i ? "current" : ""
                          } `}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="/referensi/struktur-rombel"
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrukturRombel;
