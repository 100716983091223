import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const DetailHasilTestOnline = () => {
  const inputEl = useRef(null);

  const { id_ujian } = useParams();

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const getLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ?? null;
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const [dataSoal, setDataSoal] = useState([
    { id: 1, tipe: "pilihan-ganda" },
    { id: 2, tipe: "pilihan-ganda" },
    { id: 3, tipe: "pilihan-ganda" },
    { id: 4, tipe: "pilihan-ganda" },
    { id: 5, tipe: "pilihan-ganda" },
    { id: 6, tipe: "pilihan-ganda" },
    { id: 7, tipe: "pilihan-ganda" },
    { id: 8, tipe: "pilihan-ganda" },
    { id: 9, tipe: "pilihan-ganda" },
    { id: 10, tipe: "pilihan-ganda" },
    { id: 11, tipe: "pilihan-ganda" },
    { id: 12, tipe: "pilihan-ganda" },
    { id: 13, tipe: "pilihan-ganda" },
    { id: 14, tipe: "pilihan-ganda" },
    { id: 15, tipe: "pilihan-ganda" },
    { id: 16, tipe: "pilihan-ganda" },
    { id: 17, tipe: "pilihan-ganda" },
    { id: 18, tipe: "pilihan-ganda" },
    { id: 19, tipe: "pilihan-ganda" },
    { id: 20, tipe: "pilihan-ganda" },
  ]);

  const [dataNilai, setDataNilai] = useState([
    {
      nama_peserta: "Ahmad Riyadi",
      nilai_total: 84,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Budi Santoso",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
      ],
    },
    {
      nama_peserta: "Citra Dewi",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Dian Lestari",
      nilai_total: 92,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Eka Putra",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    // Tambahkan 15 data lainnya sesuai pola di atas
    {
      nama_peserta: "Farhan Hakim",
      nilai_total: 72,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Gilang Ramadhan",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Hana Setiawan",
      nilai_total: 82,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
      ],
    },
    {
      nama_peserta: "Ilham Pratama",
      nilai_total: 70,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
      ],
    },
    {
      nama_peserta: "Joko Purwanto",
      nilai_total: 74,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Kurniawan Saputra",
      nilai_total: 78,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Linda Putri",
      nilai_total: 80,
      jawaban: [
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
      ],
    },
    {
      nama_peserta: "Maya Sari",
      nilai_total: 88,
      jawaban: [
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "A", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
    {
      nama_peserta: "Nadia Maulida",
      nilai_total: 76,
      jawaban: [
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "C", result: false },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "D", result: false },
      ],
    },
    {
      nama_peserta: "Omar Sulaiman",
      nilai_total: 86,
      jawaban: [
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 0, jawaban: "B", result: false },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "B", result: true },
        { nilai: 0, jawaban: "D", result: false },
        { nilai: 4, jawaban: "C", result: true },
        { nilai: 4, jawaban: "A", result: true },
        { nilai: 4, jawaban: "D", result: true },
      ],
    },
  ]);

  const sort = 100;
  const activePag = useRef(0);

  const [showPage, setShowPage] = useState(
    getLocalStorage("showPage") ?? "jawaban"
  );

  const [showKomposisi, setShowKomposisi] = useState(false);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleShow = (param) => {
    setLocalStorage("showPage", param);
    setShowPage(param);
  };
  const handleShowKomposisi = () => {
    setShowKomposisi(!showKomposisi);
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div onClick={handleShowKomposisi} className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Komposisi Soal</div>
          </div>
          <div>
            <span>
              <i className={`fas fa-chevron-${showKomposisi ? 'up' : 'down'}`}></i>
            </span>
          </div>
        </div>
        {showKomposisi ? (<div className="card-body">
          <div className="row">
            <div className="col-4">
              <table className="w-100 nm">
                <tr>
                  <td className="p-2 text-dark">
                    <b>Perangkai</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Donawariska</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Kode Ujian</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: U - 123456</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Jenjang</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: SMA / MA</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Kelas</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: X</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Jurusan</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: MIPA</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Mata Pelajaran</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Bahasa Indonesia</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Nama Paket Ujian</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: UTS Bahasa Indonesia</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Durasi</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: 120 (menit)</b>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-dark">
                    <b>Platform</b>
                  </td>
                  <td className="p-2 text-dark">
                    <b>: Test Online</b>
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-8">
              <div className="table">
                <div id="job_data" className="dataTables_wrapper">
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Bentuk Soal</th>
                        <th>Jumlah Soal</th>
                        <th>Bobot</th>
                        <th>Benar</th>
                        <th>Salah</th>
                        <th>Tidak Jawab</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Pilihan Ganda</td>
                        <td>25</td>
                        <td>40</td>
                        <td>4</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Sebab Akibat</td>
                        <td>25</td>
                        <td>40</td>
                        <td>4</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Uraian</td>
                        <td>25</td>
                        <td>40</td>
                        <td>4</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Penjodohan</td>
                        <td>25</td>
                        <td>40</td>
                        <td>4</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Benar Salah</td>
                        <td>25</td>
                        <td>40</td>
                        <td>4</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Jumlah Soal</td>
                        <td>50</td>
                        <td colSpan={5}>100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>) : null}
      </div>
      <div className="card dz-card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <div>
            <div className="card-title">Hasil Ujian</div>
          </div>
          <ul
            as="ul"
            className="nav nav-tabs dzm-tabs"
            id="myTab"
            role="tablist"
          >
            <li as="li" className="nav-item" role="presentation">
              <button
                className={`nav-link ${showPage == "jawaban" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("jawaban")}
              >
                Hasil
              </button>
            </li>
            <li as="li" className="nav-item">
              <button
                className={`nav-link ${showPage == "nilai" ? "active" : ""}`}
                type="button"
                onClick={() => handleShow("nilai")}
              >
                Penilaian
              </button>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc ">No</th>
                    <th className="sorting ">ID Ujian</th>
                    <th className="sorting ">Nama Peserta</th>
                    <th className="text-center ">Nilai Akhir</th>
                    {dataSoal.map((v, i) => (
                      <th>{i + 1}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {dataNilai.map((v, i) => (
                    <tr>
                      <td className="">{i + 1}</td>
                      <td className="">{id_ujian}</td>
                      <td className="">{v.nama_peserta}</td>
                      <td className="text-center ">
                        <b className="text-dark">{v.nilai_total}</b>
                      </td>
                      {dataSoal.map((value, index) => (
                        <td>
                          <span
                            className={`badge badge-sm ${
                              v.jawaban[index].result
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          >
                            {showPage == "jawaban"
                              ? v.jawaban[index].jawaban
                              : v.jawaban[index].nilai}
                          </span>{" "}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                {data.length > sort ? (
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.curreU - 1)
                      }
                    >
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to={`/hasil-ujian/detail/${id_ujian}`}
                          className={`paginate_button  ${
                            activePag.current === i ? "current" : ""
                          } `}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to={`/hasil-ujian/detail/${id_ujian}`}
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailHasilTestOnline;
