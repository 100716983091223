import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const HasilUjianTryout = () => {
  const inputEl = useRef(null);

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const [exData, setExData] = useState([
    {
      id_ujian: "U-12367123",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "RPL",
      semester: "Ganjil",
      mapel: "Matematika",
      platform:"Tryout",
      rombel: "X RPL 1",
      tanggal: "10/12/2024",
    },
    {
      id_ujian: "U-12367124",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Fisika",
      platform:"Tryout",
      rombel: "X TKJ 2",
      tanggal: "11/12/2024",
    },
    {
      id_ujian: "U-12367125",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Bahasa Indonesia",
      platform:"Tryout",
      rombel: "XI RPL 1",
      tanggal: "12/12/2024",
    },
    {
      id_ujian: "U-12367126",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "TKJ",
      semester: "Genap",
      mapel: "Bahasa Inggris",
      platform:"Tryout",
      rombel: "XI TKJ 2",
      tanggal: "13/12/2024",
    },
    {
      id_ujian: "U-12367127",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "RPL",
      semester: "Ganjil",
      mapel: "Kimia",
      platform:"Tryout",
      rombel: "X RPL 2",
      tanggal: "14/12/2024",
    },
    {
      id_ujian: "U-12367128",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "RPL",
      semester: "Ganjil",
      mapel: "Matematika",
      platform:"Test Online",
      rombel: "XII RPL 1",
      tanggal: "15/12/2024",
    },
    {
      id_ujian: "U-12367129",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "TKJ",
      semester: "Genap",
      mapel: "Fisika",
      platform:"Tryout",
      rombel: "XII TKJ 2",
      tanggal: "16/12/2024",
    },
    {
      id_ujian: "U-12367130",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "RPL",
      semester: "Ganjil",
      mapel: "Bahasa Indonesia",
      platform:"Tryout",
      rombel: "XI RPL 2",
      tanggal: "17/12/2024",
    },
    {
      id_ujian: "U-12367131",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "TKJ",
      semester: "Genap",
      mapel: "Bahasa Inggris",
      platform:"Test Online",
      rombel: "XI TKJ 1",
      tanggal: "18/12/2024",
    },
    {
      id_ujian: "U-12367132",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Kimia",
      platform:"Test Online",
      rombel: "X RPL 1",
      tanggal: "19/12/2024",
    },
    {
      id_ujian: "U-12367133",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Fisika",
      platform:"Test Online",
      rombel: "X TKJ 2",
      tanggal: "20/12/2024",
    },
    {
      id_ujian: "U-12367134",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Matematika",
      platform:"Tryout",
      rombel: "XII RPL 2",
      tanggal: "21/12/2024",
    },
    {
      id_ujian: "U-12367135",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Bahasa Indonesia",
      platform:"Tryout",
      rombel: "XII TKJ 1",
      tanggal: "22/12/2024",
    },
    {
      id_ujian: "U-12367136",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Bahasa Inggris",
      platform:"Tryout",
      rombel: "X RPL 2",
      tanggal: "23/12/2024",
    },
    {
      id_ujian: "U-12367137",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Kimia",
      platform:"Tryout",
      rombel: "X TKJ 1",
      tanggal: "24/12/2024",
    },
    {
      id_ujian: "U-12367138",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Fisika",
      platform:"Tryout",
      rombel: "XI RPL 1",
      tanggal: "25/12/2024",
    },
    {
      id_ujian: "U-12367139",
      jenjang: "SMK",
      kelas: "XI",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Bahasa Indonesia",
      platform:"Test Online",
      rombel: "XI TKJ 2",
      tanggal: "26/12/2024",
    },
    {
      id_ujian: "U-12367140",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Bahasa Inggris",
      platform:"Test Online",
      rombel: "XII RPL 1",
      tanggal: "27/12/2024",
    },
    {
      id_ujian: "U-12367141",
      jenjang: "SMK",
      kelas: "XII",
      jurusan: "TKJ",
      semester: "Ganjil",
      mapel: "Kimia",
      platform:"Test Online",
      rombel: "XII TKJ 2",
      tanggal: "28/12/2024",
    },
    {
      id_ujian: "U-12367142",
      jenjang: "SMK",
      kelas: "X",
      jurusan: "RPL",
      semester: "Genap",
      mapel: "Matematika",
      platform:"Tryout",
      rombel: "X RPL 2",
      tanggal: "29/12/2024",
    },
  ]);

  console.log("data");
  console.log(data);

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
            <div className="col-sm-4">
              <input type="search" className="form-control" placeholder="Cari ujian ..." />
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    <th className="sorting">ID Ujian</th>
                    <th className="sorting">Jen</th>
                    <th className="sorting">Kls</th>
                    <th className="sorting">Jur</th>
                    <th className="sorting">Sem</th>
                    <th className="sorting">Mata Pelajaran</th>
                    <th className="sorting">Rombel</th>
                    <th className="sorting">Tgl</th>
                    <th className="sorting text-center">Hasil</th>
                    <th className="sorting text-center">Export</th>
                  </tr>
                </thead>

                <tbody>
                  {exData.map((v, i) => (
                    <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                      <td>{i + 1}</td>
                      <td>{v.id_ujian}</td>
                      <td>{v.jenjang}</td>
                      <td>{v.kelas}</td>
                      <td>{v.jurusan}</td>
                      <td>{v.semester}</td>
                      <td>{v.mapel}</td>
                      <td>{v.rombel}</td>
                      <td>{v.tanggal}</td>
                      <td className="text-center">
                        <Link to={`/hasil-ujian/tryout/detail/${v.id_ujian}`} className="btn btn-sm btn-outline-primary">
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                      <td><button className="btn btn-sm btn-outline-success"><i className="fas fa-download"></i></button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/hasil-ujian/tryout"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.curreU-1)
                    }
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/hasil-ujian/tryout"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/hasil-ujian/tryout"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasilUjianTryout;
