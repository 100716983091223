import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import profile from "../../../images/user.jpg";
import LogoutPage from "../../layouts/nav/Logout";
import avatar from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import avatar4 from "../../../images/avatar/4.jpg";

const FooterBursa = () => {
  return (
   <div className="footer-bursa">
    <center>
        <span className="text-light">&copy; Copyright Schoolmedia 2024</span>
    </center>
   </div>
  );
};

export default FooterBursa;
