var HOST = '';
var TESTONLINE_HOST = '';
if (process.env.NODE_ENV === 'development') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    TESTONLINE_HOST = 'https://fetest.maudev.schoolmedia.my.id:8443'
}
if (process.env.NODE_ENV === 'staging') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    TESTONLINE_HOST = 'https://testonlinedev.schoolmedia.my.id'
}
if (process.env.NODE_ENV === 'production') {
    HOST = 'https://adminmaudev.schoolmedia.my.id';
    TESTONLINE_HOST = 'https://testonlinedev.schoolmedia.my.id'
}

export const HOSTNAME = HOST;
export const TESTONLINE_HOSTNAME = TESTONLINE_HOST;