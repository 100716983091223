import React, { useEffect, useState } from "react";
import HeaderBursa from "./HeaderBursa";
import bg2 from "../../../images/background/bg2.png";
import bg3 from "../../../images/background/bg3.png";
import bg4 from "../../../images/background/bg4.png";
import bg5 from "../../../images/background/bg5.png";
import bg6 from "../../../images/background/bg6.png";
import FooterBursa from "./FooterBursa";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";

const BursaKartuSoal = () => {
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");

  const [jenjang, setJenjang] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const [konten, setKonten] = useState([
    // {
    //   judul: "Kartu Soal B. Indo",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "PG",
    //   created_by: "Donawariska",
    //   cover: bg4,
    // },
    // {
    //   judul: "Kartu Soal Matematika",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "U",
    //   created_by: "Donawariska",
    //   cover: bg6,
    // },
    // {
    //   judul: "Kartu Soal IPA",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "BS",
    //   created_by: "Donawariska",
    //   cover: bg5,
    // },
    // {
    //   judul: "Kartu Soal IPS",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "SA",
    //   created_by: "Donawariska",
    //   cover: bg5,
    // },
    // {
    //   judul: "Kartu Soal Bahasa Inggris",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "P",
    //   created_by: "Donawariska",
    //   cover: bg6,
    // },
    // {
    //   judul: "Kartu Soal PKN",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "PG",
    //   created_by: "Donawariska",
    //   cover: bg6,
    // },
    // {
    //   judul: "Kartu Soal Sejarah",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "U",
    //   created_by: "Donawariska",
    //   cover: bg2,
    // },
    // {
    //   judul: "Kartu Soal Geografi",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "BS",
    //   created_by: "Donawariska",
    //   cover: bg3,
    // },
    // {
    //   judul: "Kartu Soal Ekonomi",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "SA",
    //   created_by: "Donawariska",
    //   cover: bg6,
    // },
    // {
    //   judul: "Kartu Soal Fisika",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "P",
    //   created_by: "Donawariska",
    //   cover: bg6,
    // },
    // {
    //   judul: "Kartu Soal Kimia",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "PG",
    //   created_by: "Donawariska",
    //   cover: bg4,
    // },
    // {
    //   judul: "Kartu Soal Biologi",
    //   jenis: "Kartu Soal",
    //   jenis_butir: "U",
    //   created_by: "Donawariska",
    //   cover: bg3,
    // },
  ]);

  const getKonten = async (jenjang) => {
    var response = await axiosInstance.get("/bursa/soal");
    console.log("getkonten response", response);
    var data = response.data.data;
    var tmp = []
    for (var i = 0; i < data.length; i++) {
      tmp.push({
        judul: data[i].detail.materi,
        jenis: "Kartu Soal",
        jenis_butir: data[i].type,
        created_by: data[i].created_by,
        cover: bg4,
      });
    }
    setKonten(tmp);
    // if (response.data) {
    //   var tmp = [];
    //   var data = response.data.data;
    //   for (var i = 0; i < data.length; i++) {
    //     tmp.push({ value: data[i].value, label: data[i].text });
    //   }
    //   setKonten(tmp);
    // }
  };

  useEffect(() => {
    getKonten();
  }, []);

  return (
    <>
      <HeaderBursa />
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="breadcumb d-flex">
              <b>Home </b> <span className="ms-1"> / Bursa</span>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="card">
              <div
                onClick={() => setShowFilter(!showFilter)}
                className="card-header card-header-komposisi d-flex justify-content-between flex-wrap"
              >
                <div>
                  <div className="card-title">
                    {" "}
                    <i className="fas fa-filter"></i> Filter
                  </div>
                </div>
                <div>
                  <span>
                    <i
                      className={`fas fa-chevron-${showFilter ? "up" : "down"}`}
                    ></i>
                  </span>
                </div>
              </div>
              {showFilter ? (
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <h5 className="text-primary">
                          Jenjang <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={jenjang[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenjang}
                          onChange=""
                        />
                      </div>
                      <div className="form-group mt-3">
                        <h5 className="text-primary">
                          Mata Pelajaran <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={nullSel}
                          onChange=""
                        />
                      </div>
                      <div className="form-group mt-3">
                        <h5 className="text-primary">
                          Kelas <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={nullSel}
                          onChange=""
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <h5 className="text-primary">
                          Jurusan <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={nullSel}
                          onChange=""
                        />
                      </div>
                      <div className="form-group mt-3">
                        <h5 className="text-primary">
                          Semester <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={nullSel}
                          onChange=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-5">
          {konten.map((v, i) => (
            <>
              <div className="col-sm-3 mt-4" key={i}>
                <div className="card-ctn w-100">
                  <div className="ctn-cover">
                    <img src={v.cover} className="w-100" alt="" />
                  </div>
                  <div className="ctn-v p-3">
                    <h4 className="text-dark">{v.judul}</h4>
                    <div className="mt-3">
                      <b className="text-dark">
                        <i className="fas fa-book"></i> {v.jenis}
                      </b>
                      <b className="ms-3 badge bg-primary">{v.jenis_butir}</b>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between flex-wrap">
                      <span>By {v.created_by}</span>
                      <h4>Sc. 1.000,-</h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <FooterBursa />
    </>
  );
};

export default BursaKartuSoal;
