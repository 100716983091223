import React, { useEffect, useRef, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          console.log('loader file : ', file);
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          console.log(response);
          resolve({
            default: `${response.data.url}`
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {}
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    console.log("loader : ", loader);
    return uploadAdapter(loader);
  };
}
const BenarSalah = (props) => {

  const [soal, setSoal] = useState("");
  const [pilihan, setPilihan] = useState({});

  const prevSoalValue = useRef();
  useEffect(() => {
    if (props.defaultValue != null) {
      if(!isEqual(prevSoalValue.current, soal)){
        prevSoalValue.current = soal;
        setSoal(props.defaultValue.pertanyaan);
        setPilihan(props.defaultValue.jawaban);
      }
    }
  }, [props.defaultValue])
  const handleSaveButton = () => {
    var params = {
      "pertanyaan": soal,
      "pilihan": {
        "b": "Benar",
        "s": "Salah"
      },
      "jawaban": pilihan

    };
    props.onSaveButton(params)
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="text text-primary">
            Pernyataan <span className="text-danger">*</span>
          </h5>
          <div className="col-xl-12 mt-2">
            <CKEditor
              config={{
                placeholder: "Tuliskan pernyataan di sini...",
                extraPlugins: [uploadPlugin]
              }}
              editor={ClassicEditor}
              onReady={(editor) => {}}
              data={soal}
              disabled={props.isDisabled}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSoal(data);
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <hr />
      </div>

      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="input-bs">
            <div className="row">
              <div className="col-6">
                <span className="text-primary">Benar</span>
              </div>
              <div className="col-6 text-end">
                <input
                  className="form-check-input ms-4"
                  type="radio"
                  name="gridRadios"
                  value="b"
                  onChange={(e) => setPilihan(e.target.value)}
                  checked={pilihan === "b"}
                  disabled={props.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mt-3">
          <div className="input-bs">
            <div className="row">
              <div className="col-6">
                <span className="text-primary">Salah</span>
              </div>
              <div className="col-6 text-end">
                <input
                  className="form-check-input ms-4"
                  type="radio"
                  name="gridRadios"
                  value="s"
                  onChange={(e) => setPilihan(e.target.value)}
                  checked={pilihan === "s"}
                  disabled={props.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.isDisabled ? "" : (
      <div className="row mt-3 mb-4">
        <div className="col-sm-12 text-end">
          {/* <button className="btn btn-sm m-1 btn-outline-primary">
            Simpan Draft
          </button> */}
          <button className="btn btn-sm m-1 btn-primary" onClick={handleSaveButton}>Simpan</button>
        </div>
      </div>
      )}
    </>
  );
};

export default BenarSalah;
