import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import axiosInstance from './AxiosInstance';
import { HOSTNAME } from '../constant';
// import {axiosInstance} from './AxiosInstance';
// import axiosInstance from '../services/AxiosInstance';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
/*************  ✨ Codeium Command ⭐  *************/
/**
 * Runs a logout timer. This timer is used to automatically log out the user after the time given by the timer.
 * @param {Function} dispatch - The dispatch function to trigger the logout action.
 * @param {number} timer - The time in ms to wait before triggering the logout action.
 * @param {Function} navigate - The navigate function used to navigate to the login page.
 */
/******  4cfc1ef9-2a00-4774-ae97-3fd8d95db0d2  *******/}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export async function checkAutoLogin(dispatch, navigate) {
    var cookie = getCRFToken();
    // getLogout();
    console.log(cookie);
    var me = await getMe();
    console.log(me);
    console.log(typeof me);
    console.log(me == 401);
    if (me == 401) {
        window.location.href = HOSTNAME + '/auth?redirect='+ window.location.href; 
    }
    // 
    // const tokenDetailsString = localStorage.getItem('userDetails');
    // let tokenDetails = '';
    // if (!tokenDetailsString) {
    //     dispatch(Logout(navigate));
	// 	return;
    // }

    // tokenDetails = JSON.parse(tokenDetailsString);
    else{
    let expireDate = new Date(new Date().setDate(new Date().getDate() + 1));
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(me.data.data));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
    }
}

export async function isLogin() {
    var me = await getMe();
    console.log(me);
    if (me == 401) {
        return false;
    }else{
        return true;
    }
}

export async function getCRFToken()  {
    // console.log('getCRFToken');
    return axiosInstance.get(`sanctum/csrf-cookie`, { withCredentials: true }).then(response => {
        // Axios automatically handles cookies set in the Set-Cookie header
        console.log('Response:', response.headers);
        console.log(document.cookie);
        return response.headers;
      })
      .catch(error => console.error('Error:', error));
}

export async function getMe()  {
    // console.log('getCRFToken');
    return axiosInstance.get(`me`).then(response => {
        // Axios automatically handles cookies set in the Set-Cookie header
        console.log('Response:', response);
        return response;
      })
      .catch(error => {
        console.log("error : ", error);
        return error.response.status;
      } );
}

export async function getLogout()  {
    // console.log('getCRFToken');
    return axios.get(`https://maudev.schoolmedia.my.id/auth/logout`, { withCredentials: true }).then(response => {
        // Axios automatically handles cookies set in the Set-Cookie header
        console.log('Response:', response.headers);
        console.log(document.cookie);
        return response.headers;
      })
      .catch(error => console.error('Error:', error));
}